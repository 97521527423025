import React, {useEffect} from "react";
import Container from 'react-bootstrap/Container';
import { useState } from "react";
import Row from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useNavigate} from "react-router-dom";

export const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    let navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem("jwt"))
            return navigate("/admin");
    }, []);

    const login = (e) => {
        e.preventDefault();
        fetch(process.env.REACT_APP_API_URL + "/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
                "username": username,
                "password": password
            }).toString()
        })
            .then(res => {
                if(res.status !== 200)
                    throw "Error login";
                else
                    return res.json();
            })
            .then(resData => {
                // set JWT
                localStorage.setItem("jwt", resData.token);
                window.location.pathname="/admin";
            })
            .catch(err => {
                console.log("issue " + err);
                alert("Error logging in; try again.");
            });
    };

    return (
        <Container style={{height: "100%", position: "relative"}}>
            <Row style={{position: "absolute", top: "50%", transform: "translateY(-50%) translateX(-50%)", left: "50%"}}>
                <Col>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control required value={username} onChange={val => setUsername(val.target.value)} type="text" placeholder="Enter username"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control required value={password} onChange={val => setPassword(val.target.value)} type="password" placeholder="Password"/>
                        </Form.Group>

                        <Button variant="primary" type="submit" onClick={login}>
                            Login
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};
