import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, ListGroup, Modal } from "react-bootstrap";

export const Admin = () => {
    const [staffList, setStaffList] = useState([]);
    const [newStaff, setNewStaff] = useState({ name: "", role: "" });
    const [businessHours, setBusinessHours] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [updatedName, setUpdatedName] = useState("");
    const [updatedRole, setUpdatedRole] = useState("");

    // const [pricing, setPricing] = useState();

    useEffect(() => {
        fetchStaff();
        fetchBusinessHours();
        // fetchPricing();
    }, []);
    //
    // const fetchPricing = async () => {
    //     try {
    //         const response = await fetch(process.env.REACT_APP_API_URL + "/pricing", {
    //
    //         });
    //         const data = await response.json();
    //         setPricing(data); // Assuming the response is an array of staff objects
    //     } catch (error) {
    //         console.error("Error fetching pricing:", error);
    //         alert("Error fetching pricing. Please try again later.");
    //     }
    // };

    // const updatePricing = async () => {
    //     try {
    //         // for each
    //         for (const price of pricing) {
    //             try {
    //                 await fetch(process.env.REACT_APP_API_URL + `/pricing/${price.id}`, {
    //                     method: "PUT",
    //                     headers: {
    //                         "Content-Type": "application/x-www-form-urlencoded",
    //                         Authorization: localStorage.getItem("jwt"),
    //                     },
    //                     body: new URLSearchParams({ price: price.price }).toString(),
    //                 });
    //                 await fetchPricing(); // Refresh the staff list after updating
    //             } catch (error) {
    //                 console.error("Error updating pricing:", error);
    //                 alert("Error updating pricing. Please try again later.");
    //             }
    //         }
    //     } catch (error) {
    //         console.error("Error updating pricing:", error);
    //         alert("Error updating pricing. Please try again later.");
    //     }
    // };

    const fetchStaff = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/staff-members", {

            });
            const data = await response.json();
            setStaffList(data); // Assuming the response is an array of staff objects
        } catch (error) {
            console.error("Error fetching staff:", error);
            alert("Error fetching staff. Please try again later.");
        }
    };

    const fetchBusinessHours = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/business-hours", {
                headers: {
                    Authorization: localStorage.getItem("jwt"),
                },
            });
            const data = await response.json();
            setBusinessHours(data); // Assuming the response is an array of business hours objects
        } catch (error) {
            console.error("Error fetching business hours:", error);
            alert("Error fetching business hours. Please try again later.");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewStaff({ ...newStaff, [name]: value });
    };

    const addStaff = async () => {
        try {
            await fetch(process.env.REACT_APP_API_URL + "/staff-members", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: localStorage.getItem("jwt"),
                },
                body: new URLSearchParams(newStaff).toString(),
            });
            fetchStaff(); // Refresh the staff list after adding
            setNewStaff({ name: "", role: "" }); // Clear the input fields
        } catch (error) {
            console.error("Error adding staff:", error);
            alert("Error adding staff. Please try again later.");
        }
    };

    const updateStaff = async (id) => {
        try {
            const staffToUpdate = staffList.find((staff) => staff.id === id);
            setSelectedStaff(staffToUpdate);
            setUpdatedName(staffToUpdate.name);
            setUpdatedRole(staffToUpdate.role);
            setShowModal(true);
        } catch (error) {
            console.error("Error updating staff:", error);
            alert("Error updating staff. Please try again later.");
        }
    };

    const deleteStaff = async (id) => {
        try {
            await fetch(process.env.REACT_APP_API_URL + `/staff-members/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: localStorage.getItem("jwt"),
                },
            });
            fetchStaff(); // Refresh the staff list after deletion
        } catch (error) {
            console.error("Error deleting staff:", error);
            alert("Error deleting staff. Please try again later.");
        }
    };

    const updateBusinessHours = async () => {
        // Assuming there is an input field to update business hours
        try {
            await Promise.all(businessHours.map(async (hour) => {
                await fetch(process.env.REACT_APP_API_URL + `/business-hours/${hour.id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: localStorage.getItem("jwt"),
                    },
                    body: new URLSearchParams(hour).toString(),
                });
            }));
            fetchBusinessHours(); // Refresh the business hours after updating
        } catch (error) {
            console.error("Error updating business hours:", error);
            alert("Error updating business hours. Please try again later.");
        }
    };


    const handleUpdate = async () => {
        try {
            await fetch(process.env.REACT_APP_API_URL + `/staff-members/${selectedStaff.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: localStorage.getItem("jwt"),
                },
                body: new URLSearchParams({ name: updatedName, role: updatedRole }).toString(),
            });
            setShowModal(false);
            fetchStaff(); // Refresh the staff list after updating
        } catch (error) {
            console.error("Error updating staff:", error);
            alert("Error updating staff. Please try again later.");
        }
    };

    const handleBusinessHoursChange = (day, field, value) => {
        setBusinessHours((prevBusinessHours) => {
            const updatedHours = [...prevBusinessHours];
            const dayIndex = updatedHours.findIndex((item) => item.day === day);
            updatedHours[dayIndex][field] = value;
            return updatedHours;
        });
    };

    return (
        <Container style={{ marginTop: "7.5%" }}>
            <div style={{margin: "0 auto"}}>
                <h1 className="mt-5 mb-3">Staff Management</h1>
                <Row>
                    <Col>
                        <h2>Add New Staff</h2>
                        <Form>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter name"
                                    name="name"
                                    value={newStaff.name}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="formRole">
                                <Form.Label>Role</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter role"
                                    name="role"
                                    value={newStaff.role}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Button className="mr-2" variant="primary" onClick={addStaff}>
                                Add Staff
                            </Button>
                        </Form>
                    </Col>
                    <Col>
                        <h2>Staff List</h2>
                        <ListGroup>
                            {staffList.map((staff) => (
                                <ListGroup.Item key={staff.id}>
                                    {staff.name} - {staff.role}
                                    <Button style={{margin: "10px"}} className="ml-2" variant="info"
                                            onClick={() => updateStaff(staff.id)}>
                                        Update
                                    </Button>
                                    <Button className="ml-2" variant="danger" onClick={() => deleteStaff(staff.id)}>
                                        Delete
                                    </Button>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>
                {/*<h1 className="mt-5 mb-3">Pricing Management</h1>*/}
                {/*<Row>*/}
                {/*    <Col>*/}
                {/*        <h2>Add New Staff</h2>*/}
                {/*        <Form>*/}
                {/*            <Form.Group controlId="formName">*/}
                {/*                <Form.Label>Haircut</Form.Label>*/}
                {/*                <Form.Control*/}
                {/*                    type="text"*/}
                {/*                    placeholder="Enter name"*/}
                {/*                    name="name"*/}
                {/*                    value={pricing[0]["price"]}*/}
                {/*                    onChange={val => {*/}
                {/*                        let tempPricing = [...pricing];*/}
                {/*                        tempPricing[0]["price"] = val;*/}
                {/*                        setPricing(tempPricing);*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            </Form.Group>*/}
                {/*            <Form.Group controlId="formName">*/}
                {/*                <Form.Label>Haircut</Form.Label>*/}
                {/*                <Form.Control*/}
                {/*                    type="text"*/}
                {/*                    placeholder="Enter name"*/}
                {/*                    name="name"*/}
                {/*                    value={pricing[0]["price"]}*/}
                {/*                    onChange={ val => {*/}
                {/*                        let tempPricing = [...pricing];*/}
                {/*                        tempPricing[1]["price"] = val;*/}
                {/*                        setPricing(tempPricing);*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            </Form.Group>*/}
                {/*            <Button className="mr-2" variant="primary" onClick={addStaff}>*/}
                {/*                Add Staff*/}
                {/*            </Button>*/}
                {/*        </Form>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row>*/}
                {/*    <Col>*/}
                {/*        <h2>Schedule</h2>*/}
                {/*        <Form>*/}
                {/*            {businessHours.map((hour) => (*/}
                {/*                <Row key={hour.day} style={{margin: "0 auto"}}>*/}
                {/*                    <Col>*/}
                {/*                        <Form.Group controlId={`start-time-${hour.day}`}>*/}
                {/*                            <Form.Label>Start Time</Form.Label>*/}
                {/*                            <Form.Control*/}
                {/*                                type="time"*/}
                {/*                                value={hour.startTime}*/}
                {/*                                onChange={(e) => handleBusinessHoursChange(hour.day, "startTime", e.target.value)}*/}
                {/*                            />*/}
                {/*                        </Form.Group>*/}
                {/*                        <Form.Group controlId={`end-time-${hour.day}`}>*/}
                {/*                            <Form.Label>End Time</Form.Label>*/}
                {/*                            <Form.Control*/}
                {/*                                type="time"*/}
                {/*                                value={hour.endTime}*/}
                {/*                                onChange={(e) => handleBusinessHoursChange(hour.day, "endTime", e.target.value)}*/}
                {/*                            />*/}
                {/*                        </Form.Group>*/}

                {/*                    </Col>*/}
                {/*                </Row>*/}
                {/*            ))}*/}
                {/*            <Button className="mr-2" variant="primary" onClick={updateBusinessHours}>*/}
                {/*                Update Business Hours*/}
                {/*            </Button>*/}
                {/*        </Form>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Modal show={showModal} onHide={() => setShowModal(false)} style={{opacity: 1, paddingTop: "20%"}}>
                    <Modal.Header>
                        <Modal.Title>Update Staff</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter name"
                                    value={updatedName}
                                    onChange={(e) => setUpdatedName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formRole">
                                <Form.Label>Role</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter role"
                                    value={updatedRole}
                                    onChange={(e) => setUpdatedRole(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleUpdate}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Container>
    );
};
