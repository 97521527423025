import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Admin } from "./components/Admin";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { Login } from "./components/Login";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  //   const [pricing, setPricing] = useState();
  //
  //   useEffect(() => {
  //       // get staff members
  //       fetch(process.env.REACT_APP_API_URL + "/pricing")
  //           .then(res => res.json())
  //           .then(json => {
  //                setPricing(json);
  //           })
  //           .catch(_ => alert("Error"));
  //       setLandingPageData(JsonData);
  // }, []);

  return (
      <BrowserRouter>
          <Routes>
              <Route path={"/"} element={
                  <>
                      <Navigation/>
                      <Header/>
                      <About data={landingPageData.About}/>
                      <Services data={landingPageData.Services}/>
                      <Team data={landingPageData.Team}/>
                      <Contact data={landingPageData.Contact}/>
                  </>
              }/>
              <Route path={"/login"} element={
                  <>
                      <Navigation/>
                      <Login/>
                  </>
              }/>
              <Route path={"/admin"} element={
                  <>
                      <Navigation/>
                      <Admin/>
                  </>
              }/>
          </Routes>
      </BrowserRouter>
  );
};

export default App;
