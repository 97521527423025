import React, {useEffect, useState} from "react";

export const Team = (props) => {
  const [staff, setStaff] = useState([]);

  // get staff members
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/staff-members")
        .then(res => res.json())
        .then(json => setStaff(json))
        .catch(_ => console.log(_));
  }, []);

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Barbers</h2>
          <p>
            Each and every one is dedicated to quality service.
          </p>
        </div>
        <div id="row">
          {/* this will be state-controlled */}
          {staff
            ? staff.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 col-sm-4 team">
                  <div className="thumbnail">
                    {" "}
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <hr/>
                      <p>{d.role}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
