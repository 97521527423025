import React from "react";

export const Header = () => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row" style={{
              width: "100%",
              height: "100%",
              position: "relative",
              margin: "auto"
            }}>
              <div className="col-md-8 col-md-offset-2 intro-text">
                {/*<h1>*/}
                {/*  Manny's Unisex Hair Salon*/}
                {/*  <span></span>*/}
                {/*</h1>*/}
                <img src="img/logo-nobg.png" className="img-responsive" alt="Manny's" style={{
                  width: "75%",
                  height: "auto"
                }}/>

                <a
                  href="https://booksy.com/en-us/433122_manny-s-unisex_barber-shop_30865_armonk?hl=en-US&gei=RXJKZpvgHZ7l5NoPyISa4A0&rwg_token=AJKvS9VsAe1y07JpLkb_Eh5vVkH-63kW5sTfq8TokuAYYpgo7huzatWvCQn2Hy_apAHlPMDRWU8LkjCQ_6wtGFnww0dEqHx3WA%3D%3D"
                  className="btn btn-custom btn-lg page-scroll"
                  style={{marginTop: "2.5%"}}
                  target={"_blank"} rel="noreferrer"
                >
                  Book Now
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
