import React, {useEffect, useState} from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Pricing</h2>
          <p>
            We offer various services to keep you looking fresh!
          </p>
        </div>
          <div className="row">
              <div className="col-md-4">
                  {/*<i className={d.icon}></i>*/}
                  <div className="service-desc" style={{margin: "0 auto"}}>
                      <h3>Haircut - $28</h3>
                      <p>A Manny's staple for over 40 years</p>
                  </div>
              </div>
              <div className="col-md-4">
                  <div className="service-desc" style={{margin: "0 auto"}}>
                      <h3>Fades/Tape-Ups - $30 </h3>
                      <p>A perfect option to look extra fresh</p>
                  </div>
              </div>
              <div className="col-md-4">
                  <div className="service-desc" style={{margin: "0 auto"}}>
                      <h3>Fades/Tape-Ups & Beard Trim - $35</h3>
                      <p>The key to looking perfect</p>
                  </div>
              </div>
              <div className="col-md-4">
                  <div className="service-desc" style={{margin: "0 auto"}}>
                      <h3>Beard Trim - $5</h3>
                      <p>A simple cleanup</p>
                  </div>
              </div>
              <div className="col-md-4">
                  <div className="service-desc" style={{margin: "0 auto"}}>
                      <h3>Women's Haircut - $30</h3>
                      <p>Simple yet effective for women</p>
                  </div>
              </div>
              <div className="col-md-4">
                  <div className="service-desc" style={{margin: "0 auto"}}>
                      <h3>Women's Blow Dry w/ Shampoo - $40</h3>
                      <p>The right fit for women</p>
                  </div>
              </div>
              <div className="col-md-4">
              </div>
              <div className="col-md-4">
                  <div className="service-desc" style={{margin: "0 auto"}}>
                      <h3>Women's Blow Dry Only - $35</h3>
                      <p>A nice refresh</p>
                  </div>
              </div>
              <div className="col-md-4">
              </div>
          </div>
      </div>
    </div>
  );
};
