import {useState} from "react";
import emailjs from "emailjs-com";
import React from "react";

export const Contact = (props) => {

    return (
        <div>
            <div id="contact">
                <div className="container">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="section-title">
                                <h2>Hours</h2>
                                {/*  to be state-controlled */}
                                <ul className={"contact-hours"}>
                                    <li>Monday: Closed</li>
                                    <li>Tuesday: 8:00 AM - 5:00 PM</li>
                                    <li>Wednesday: 8:00 AM - 5:00 PM</li>
                                    <li>Thursday: 8:00 AM - 5:00 PM</li>
                                    <li>Friday: 8:00 AM - 5:00 PM</li>
                                    <li>Saturday: 8:00 AM - 4:30 PM</li>
                                    <li>Sunday: Closed</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-md-offset-1 contact-info">
                        <div className="contact-item">
                            <h3>Contact Info</h3>
                            <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                                416 Main St, Armonk, NY 10504
                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                                (914) 273-3740
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="footer">
                <div className="container text-center">
                    <p>
                        &copy;{new Date().getFullYear()} Manny's Unisex Hair Salon
                        <br/> Made by {" "}
                        <a href="https://simplexwebsites.com" rel="nofollow noreferrer" target={"_blank"}>
                            Simplex Websites
                        </a>
                        <br/>
                        {
                          localStorage.getItem("jwt") ?
                              <>
                                  <a style={{fontSize: "12px"}} href={"/#page-top"} onClick={() => {
                                      localStorage.removeItem("jwt");
                                      window.location.reload();
                                  }} rel="nofollow">
                                    Admin Logout
                                  </a> |
                                  <a style={{fontSize: "12px"}} href={"/admin"}> Edit Site</a>
                              </>
                          : <a style={{fontSize: "12px"}} href="/login" rel="nofollow"> Admin Login </a>
                        }
                    </p>
                </div>
            </div>
        </div>
    );
};
